import React, {useEffect, useRef} from 'react';
import CartPartialView from "./CartPartialView";
import CopyRight from "./CopyRight";

const UserGaleryOrderView = ({galery,cart,greeting,images,imagesBase,onBackToImages,onShowImage,onCloseImage,cartOpen,
                                 onContinueToOrder,onContinueToCart,onSubmitOrder,cities,state,onChaneZip,...props}) => {
    const forwardProps = {imagesBase,cart};
    const orderForm = useRef();
    useEffect(() => {
        window.scroll(0,0)
    },[])
    return (
        <div className="wrapper">

            <div className="main-panel">
                <div className="cover-under-dropdown"></div>
                <nav className="navbar navbar-default user-navbar">
                    <div className="cover-under-dropdown-backdrop-lighter"></div>
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <a className="navbar-brand" href="#charts">{galery && "Galéria: "+galery.name+" – Megrendelés"}</a>
                        </div>
                    </div>
                </nav>

                <div className="content ">
                    <div className="cover-under-dropdown-backdrop"></div>
                    <div className="container-fluid main-container">
                        <div className="card order-page-cart">
                            <div className="card-header">
                                <h4 className="card-title">Kosár</h4>
                            </div>
                            <div className="card-content">
                                <CartPartialView allowChange={false} {...forwardProps} showZeroItems={false} />
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-default" onClick={onContinueToCart}>Vissza a kosárhoz</button>
                                <button className="btn btn-primary pull-right" onClick={() => {orderForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });}}>Tovább</button>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="card order-page-cart">
                            <form ref={orderForm} onSubmit={onSubmitOrder} className="form-horizontal">
                                <div className="card-header">
                                    <h4 className="card-title">Megrendelés</h4>
                                </div>
                                <div className="card-content">
                                    <div className="form-group">
                                        <label className="col-sm-2 control-label">Név</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" name="name" required/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-2 control-label">Email</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" name="email" required
                                                   pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-2 control-label">Telefonszám</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" name="phone"
                                                   required/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-2 control-label">Irányítószám</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" onChange={onChaneZip} name="zip" required
                                                   pattern='^[0-9]{4}$'/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-2 control-label">Telelülés</label>
                                        <div className="col-sm-10">
                                            {cities.length===0?
                                            <input key="city-user" type="text" className="form-control" name="city" required />
                                            :(cities.length===1?
                                                    <input key="city-given" type="text" className="form-control" name="city" defaultValue={cities[0]} disabled required />
                                                :<select name="cars" className="form-control" required>
                                                    {cities.map((city) => <option value={city}>{city}</option>)}
                                            </select>)}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-2 control-label">Megye</label>
                                        <div className="col-sm-10">
                                            {state===null?
                                                <input key="state-user" type="text" className="form-control" name="state" defaultValue="" required />
                                                :<input key="state-given" type="text" className="form-control" name="state" defaultValue={state} disabled required />
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-2 control-label">Utca, házszám</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" name="address" required />
                                        </div>
                                    </div>
                                    {cart.totalAmount ?
                                        <>
                                            <div className="form-group form-group-payment-method">
                                                <label className="col-sm-2 control-label">Fizetési mód</label>
                                                <div className="col-sm-10">
                                                    <div className="input-radio" onClick={(ev)=>{
                                                        ev.currentTarget.querySelector('input').checked = true;
                                                    }}>
                                                        <input type="radio" name="payment" value="0" defaultChecked={true}/>
                                                        <label>
                                                            Bankkártya
                                                        </label>
                                                    </div>
                                                    <div className="input-radio" onClick={(ev)=>{
                                                        ev.currentTarget.querySelector('input').checked = true;
                                                    }}>
                                                        <input type="radio" name="payment" value="1"/>
                                                        <label>
                                                            Átutalás
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="form-group payment-information">
                                                <label className="col-sm-2 control-label" />
                                                <div className="col-sm-10"><p>A fizetés a
                                                    <a className="simplepay-logo"
                                                       href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"
                                                       target="_blank" rel="noreferrer noopener">
                                                        <img src={process.env.PUBLIC_URL+"/assets/img/simplepay_w140.png"} alt="SimplePay" />
                                                    </a>
                                                    szolgáltatásán keresztül történik. Kérjük, hogy az alábbi nyilatkozat bejelölésével
                                                    járuljon hozzá az adatainak továbbításához.</p></div>
                                            </div>
                                            <div className="form-group form-group-aff">
                                                <label className="col-sm-2 control-label" />
                                                <div className="col-sm-10">
                                                    <div className="aff-checkbox-group">
                                                        <input type="checkbox" name="aff" required />
                                                        <label className="aff-label"><p className="small">Tudomásul veszem,
                                                            hogy Illyés Andrea Bernadette Ev. (4028 Debrecen Laktanya
                                                            u. 21. 3/7) adatkezelő
                                                            által a <a href="https://bettyfoto.hu" target="_blank" rel="noreferrer noopener">bettyfoto.hu</a> felhasználói
                                                            adatbázisában
                                                            tárolt alábbi személyes adataim átadásra kerülnek az OTP Mobil
                                                            Kft., mint
                                                            adatfeldolgozó részére. Az adatkezelő által továbbított adatok
                                                            köre az
                                                            alábbi: név, e-mail cím, számlázási cím.
                                                            Az adatfeldolgozó által végzett adatfeldolgozási tevékenység
                                                            jellege és
                                                            célja a SimplePay Adatkezelési tájékoztatóban, az alábbi linken
                                                            tekinthető
                                                            meg: <a
                                                                href="http://simplepay.hu/vasarlo-aff">http://simplepay.hu/vasarlo-aff</a>
                                                        </p></label>
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                        : null
                                    }
                                    <div className="form-group">
                                        <label className="col-sm-2 control-label">Megjegyzés</label>
                                        <div className="col-sm-10">
                                            <textarea rows="3" className="form-control" name="comment" />
                                        </div>
                                    </div>
                                </div>

                                <div className="card-footer">
                                    {cart.digitalOnlyNotAllowed && <div className="alert alert-danger" role="alert">
                                        <span data-notify="message">Digitális fotó rendeléséhez még {cart.digitalOnlyNotAllowed} db digitális fotót válassz.</span>
                                        <a href="#" target="_blank" data-notify="url"></a>
                                    </div>}
                                    <button type="submit" disabled={cart.digitalOnlyNotAllowed} title={cart.digitalOnlyNotAllowed ? `Digitális fotó rendeléséhez még ${cart.digitalOnlyNotAllowed} db digitális fotót válassz.` : null} className="btn btn-primary pull-right">Megrendelés</button>
                                    <div className="clearfix"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <footer className="footer">
                    <div className="container-fluid">
                        <CopyRight />
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default UserGaleryOrderView;