import React, {Component} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import LoginView from "./view/LoginView";
import axios from "axios";
import Page from "./page/Page";

class LoginPage extends Page {

    componentDidMount() {
        this.from = this.props.location.state?.from;
    }

    loginWithCredentials = async (username,password,onInvalidCredentials) => {
        try {
            let response = await axios.post(process.env.REACT_APP_API_URL + '/login',{username,password})
            let token = response.data?.token;
            if (token) {
                this.props.logInWithToken(token,()=>{
                    this.notify('Sikeres bejelentkezés')
                    if (this.from) {
                        this.props.history.push(this.from.toString());
                    } else {
                        this.props.history.push('/admin')
                    }
                });
            }
        } catch (e) {
            if (e.response?.status === 401) {
                console.error(e.response.data);
                this.error('Hibás felhasználónév/jelszó');
                onInvalidCredentials();
            } else {
                this.handleError(e)
            }
        }
    }

    render() {
        return (
            <LoginView {...this.props} loginWithCredentials={this.loginWithCredentials} />
        );
    }
}

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            token: null
        }
        const retrievedstate = window.localStorage.getItem('ib_login');
        if (retrievedstate) {
            this.state = JSON.parse(retrievedstate);
        }
    }

    /*static getDerivedStateFromProps(props, state) {

    }*/

    logInWithToken = (token,cb) => {
        const newstate = {loggedIn: true,token};
        window.localStorage.setItem('ib_login',JSON.stringify(newstate))
        this.setState(newstate,cb instanceof Function ? cb : undefined)
    }

    logOut = (cb) => {
        const newstate = {loggedIn: false,token:null};
        window.localStorage.setItem('ib_login',JSON.stringify(newstate))
        this.setState({loggedIn: false,token:null},cb instanceof Function ? cb : undefined)
    };

    render() {
        let loginProps = {token: this.state.token, loggedIn: this.state.loggedIn, logInWithToken: this.logInWithToken, logOut: this.logOut}
        return (
            <Switch>
                <Route path={this.props.match.path+"/login"} exact={true} render={(props) => <LoginPage {...props} {...loginProps} />} />
                {this.state.loggedIn
                    ?
                    <Route path={this.props.match.path} render={(props) => this.props.render({...props,...loginProps})} />
                    :
                    <Redirect to={{pathname: this.props.match.path+"/login", state:{from:this.props.location}}} />
                }
            </Switch>
        );
    }
}

export default Login;