import React, {useRef} from 'react';

const LoginView = ({loginWithCredentials}) => {
    const usernameRef = useRef();
    const passwordRef = useRef();

    return (
        <>
            <nav className="navbar navbar-transparent navbar-absolute">
                <div className="container">
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/admin">Illyés Bernadette Fotogaléria</a>
                    </div>
                </div>
            </nav>

            <div className="wrapper wrapper-full-page">
                <div className="full-page login-page" data-color=""
                     data-image="../../assets/img/background/background-2.jpg">
                    <div className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                                    <form onSubmit={(ev) => {
                                        ev.preventDefault();
                                        loginWithCredentials(usernameRef.current.value,passwordRef.current.value,() => {
                                            passwordRef.current.value = '';
                                            passwordRef.current.focus();
                                        });

                                    }}>
                                        <div className="card" data-background="color" data-color="blue">
                                            <div className="card-header">
                                                <h3 className="card-title">Bejelentkezés</h3>
                                            </div>
                                            <div className="card-content">
                                                <div className="form-group">
                                                    <label>Felhasználónév</label>
                                                    <input ref={usernameRef} type="text" placeholder="Felhasználónév"
                                                           className="form-control input-no-border" name="username" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Jelszó</label>
                                                    <input ref={passwordRef} type="password" placeholder="Jelszó"
                                                           className="form-control input-no-border" name="password" />
                                                </div>
                                            </div>
                                            <div className="card-footer text-center">
                                                <button type="submit" className="btn btn-fill btn-wd ">Küld</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer footer-transparent">
                        <div className="container">
                            <div className="copyright">
                                &copy;
                                <script>document.write(new Date().getFullYear())</script>
                                , made with <i className="fa fa-heart heart"></i> by <a
                                href="https://www.creative-tim.com">Creative Tim</a>
                            </div>
                        </div>
                    </footer>

                    <div className="full-page-background login-background"></div>
                </div>
            </div>
        </>
    );
};

export default LoginView;