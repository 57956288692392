import React from 'react';

const CartPartialMobileView = ({allowChange,imagesBase,cart,onCartPlus,onCartMinus,onCartRemove,showZeroItems=true}) => {
    return (

        <div>
            {cart.items.length
                ?
                <>
                    {cart.items.map((item,index) => (
                        !showZeroItems&&item.count===0
                            ?
                            null
                            :
                            <div key={index} className="cart-item">
                                <div className="cart-item-row-1">
                                    <div><img className="cart-image"
                                        data-orientation="landscape"
                                        src={item.image.smallimage}
                                        alt={item.image.name}/></div>
                                    <div className="cart-item-name">{item.image.name}</div>
                                </div>
                                <div className="cart-item-row-2">
                                    <div className="cart-item-size">{item.size.size}</div>

                                    {allowChange===false
                                        ?
                                        <div className="cart-item-count">{item.count} db</div>
                                        :
                                        <>
                                            <div className="cart-item-remove">
                                                <button
                                                    onClick={() => onCartRemove(item)}
                                                    className="cart-remove-button btn btn-simple btn-danger btn-icon">
                                                    <i className="material-icons material-icons--outlined">clear</i>
                                                </button>
                                            </div>
                                            <div className="cart-item-count">{item.count} db</div>
                                            <div className="cart-item-plus-minus">
                                                <button
                                                    onClick={() => onCartPlus(item)}
                                                    className="cart-plus-minus-button btn btn-simple btn-success btn-icon">
                                                    <i className="material-icons material-icons--outlined">add_circle_outlined</i>
                                                </button>
                                                <button
                                                    onClick={() => onCartMinus(item)}
                                                    className="cart-plus-minus-button btn btn-simple btn-danger btn-icon">
                                                    <i className="material-icons material-icons--outlined">remove_circle_outlined</i>
                                                </button>
                                            </div>
                                        </>
                                    }

                                    <div className="cart-item-price">{item.size.price * item.count} Ft</div>
                                </div>

                            </div>
                    ))}
                    <div className="cart-total-count-container">
                        <div className="cart-total-count-desc">Összes darab</div>
                        <div className="cart-total-count"><b style={{whiteSpace: "nowrap"}}>{cart.totalCount} db</b></div>
                    </div>
                    <div className="cart-total-price-container">
                        <div className="cart-total-price-desc">Fizetendő</div>
                        <div className="cart-total-price"><b style={{whiteSpace: "nowrap"}}>{cart.totalAmount} Ft</b></div>
                    </div>
                </>
                :
                (<tr><td>A kosár üres</td></tr>)}

        </div>
    )

};

export default CartPartialMobileView;