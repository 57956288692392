import React, {useEffect} from 'react';
import sanitizeHtml from 'sanitize-html';
import CartPartialView from "./CartPartialView";
import CopyRight from "./CopyRight";
import CartPartialMobileView from "./CartPartialMobileView";
import {ShoppingCartOutlined} from "@material-ui/icons";

let lastPageYOffset = 0;

const UserGaleryView = ({width,galery,cart,images,imagesBase,bigimage,onShowImage,onCloseImage,cartOpen,onCloseCart,onToggleCart,onAddToCart,onCartPlus,onCartMinus,onCartRemove,onContinueToCart,onContinueToOrder}) => {
    const forwardProps = {imagesBase,cart,onCartPlus,onCartMinus,onCartRemove};
    const isMobile = width < 768;
    useEffect(() => {
        window.scroll(0,lastPageYOffset)
        return () => {
            lastPageYOffset = window.pageYOffset
        }
    },[])
    if (isMobile && cartOpen) {
        return <div className="wrapper">
            <div className={`main-panel ${cartOpen?"dropdown-open":""}`}>
                <nav className="navbar navbar-default user-navbar">
                    <div className="cover-under-dropdown-backdrop-lighter"/>
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <div className="page-title">
                                <h1 className="page-title-one">{galery.title?galery.title:'Illyés Bernadette fényképész'}</h1>
                                <h3 className="page-title-two">{galery.subtitle?galery.subtitle:'30/539-8747'}</h3>
                            </div>
                            <div className={`cart-button-container ${cartOpen?"open":""}`}>
                                <button onClick={onContinueToCart} className="cart-button btn btn-simple btn-icon btn-danger">

                                    <ShoppingCartOutlined style={{fontSize: "28px"}} />{cart.items.length ?
                                    <span className="badge">{cart.count}</span> : null}
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="content ">
                    <div className="container-fluid main-container">
                        <div className="card cart-mobile">
                            <div className="card-header">
                                <h4 className="card-title">Kosár</h4>
                            </div>
                            <div className="card-content">
                                <CartPartialMobileView {...forwardProps} />
                            </div>
                            <div className="card-footer">
                                <div className="cart-actions">
                                    <button onClick={onCloseCart} className="continue-shopping-button btn btn-info">Folytatom a
                                        vásárlást
                                    </button>
                                    <button onClick={onContinueToOrder} className="continue-to-orders-button btn btn-success">Tovább a megrendeléshez</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className="footer">
                    <div className="container-fluid">
                        <CopyRight />
                    </div>
                </footer>
            </div>
            <div className={`bigimage-overlay ${bigimage?"shown":""}`}>
                <button onClick={onCloseImage} className="bigimage-overlay-back btn btn-simple btn-info btn-icon">
                    <i className="material-icons material-icons--outlined">navigate_before</i>
                </button>
                <button onClick={onCloseImage} className="bigimage-overlay-close btn btn-simple btn-danger btn-icon">
                    <i className="material-icons material-icons--outlined">close</i>
                </button>
                <img className="bigimage" src={bigimage?(bigimage):undefined} alt=""/>
            </div>
        </div>
    } else
    return (
        <div className="wrapper">
            <div className={`main-panel ${cartOpen?"dropdown-open":""}`}>
                <div onClick={onCloseCart} className="cover-under-dropdown"/>
                <nav className="navbar navbar-default user-navbar">
                    <div onClick={onCloseCart} className="cover-under-dropdown-backdrop-lighter"/>
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <div className="page-title">
                                <h1 className="page-title-one">{galery.title?galery.title:'Illyés Bernadette fényképész'}</h1>
                                <h3 className="page-title-two">{galery.subtitle?galery.subtitle:'30/539-8747'}</h3>
                            </div>
                            <div className={`cart-button-container ${cartOpen?"open":""}`}>
                                <button onClick={onContinueToCart} className="cart-button btn btn-simple btn-icon btn-danger">
                                    <ShoppingCartOutlined style={{fontSize: "28px"}} />{cart.items.length ?
                                    <span className="badge">{cart.count}</span> : null}
                                </button>
                                <div className="cart-dropdown card">
                                    <div className="cart-dropdown-container">
                                        <div className="card-header">
                                            <button onClick={onCloseCart} className="cart-close-button btn btn-icon btn-fill btn-danger">
                                                <i className="material-icons material-icons--outlined">close</i>
                                            </button>
                                            <h4 className="card-title">Kosár</h4>

                                        </div>
                                        <div className="card-content">
                                            <CartPartialView {...forwardProps} />
                                        </div>
                                        <div className="card-footer">
                                            <div className="cart-actions">
                                                <button onClick={onCloseCart} className="continue-shopping-button btn btn-info">Folytatom a
                                                    vásárlást
                                                </button>
                                                <button onClick={onContinueToOrder} className="btn btn-success">Tovább a megrendeléshez</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="content">
                    <div onClick={onCloseCart} className="cover-under-dropdown-backdrop"/>
                    <div className="container-fluid main-container">
                        {galery && galery.greeting ? (
                            <div className="card user-galery-intro">
                                <div className="card-content" dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(galery.greeting,{
                                        allowedTags: [ 'h1', 'h2', 'h3', 'b', 'i', 'em', 'strong', 'a' ],
                                        allowedAttributes: {
                                            'a': [ 'href' ]
                                        }
                                    })}} />
                            </div>
                        ):null}
                        <div className="card card-plain galery-image-list-card">
                            <div className="card-content">
                                {images?(
                                    <ul className="galery-image-list">
                                        {images.map((image,index) => (
                                            <li key={index} className={`galery-item galery-item-userside ${cart.imageIds[image.id] !== undefined?"galery-item-selected":""}`}>
                                                <div className="galery-item-contents card">
                                                    <a className="galery-image-container" href={image.fullimage} onClick={(ev) => {ev.preventDefault(); onShowImage(image)}} target="_blank" rel="noopener noreferrer">
                                                        <img className="galery-image" src={image.smallimage} alt="" />
                                                    </a>
                                                    <div className="galery-item-footer">
                                                        <span>{image.name}</span>
                                                        <button
                                                            onClick={() => onAddToCart(image)}
                                                            className="add-to-cart-button btn btn-simple btn-info btn-icon">
                                                            <ShoppingCartOutlined style={{fontSize: "24px"}} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                ):(
                                    <p>Nincs kép</p>
                                )}

                            </div>
                        </div>
                    </div>
                </div>

                <footer className="footer">
                    <div className="container-fluid">
                        <CopyRight />
                    </div>
                </footer>
            </div>
            <div className={`bigimage-overlay ${bigimage?"shown":""}`}>
                <button onClick={onCloseImage} className="bigimage-overlay-back btn btn-simple btn-info btn-icon">
                    <i className="material-icons material-icons--outlined">navigate_before</i>
                </button>
                <button onClick={onCloseImage} className="bigimage-overlay-close btn btn-simple btn-danger btn-icon">
                    <i className="material-icons material-icons--outlined">close</i>
                </button>
                <img className="bigimage" src={bigimage?(bigimage):undefined} alt=""/>
            </div>
        </div>
    );
};

export default UserGaleryView;