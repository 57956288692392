import React from 'react';
import axios from "axios";
import Page from "./Page";
import OrdersView from "../view/OrdersView";

class GaleriesPage extends Page {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            orders: [],
            galery: null
        }
    }

    update(searchTerm) {
        this.setState({ loading:true },() => {
            axios.get(process.env.REACT_APP_API_URL+'/galery/'+this.props.galeryId+'/orders'+(searchTerm?'?search='+encodeURIComponent(searchTerm):''),this.axiosConfig())
                .then(result=>{
                    return result.data
                })
                .then(data=>{
                    return data.data
                })
                .then(data=>{
                    this.setState({orders: data,loading:false})
                })
                .catch(reason => {
                    this.handleError(reason);
                    this.setState({loading:false})
                })
        });
    }

    delete(id) {
        this.setState({ loading:true },() => {
            axios.delete(process.env.REACT_APP_API_URL + '/order/' + id,this.axiosConfig())
                .then(result => {
                    this.notify('A rendelés #'+id+' törlése sikerült')
                    this.update();
                })
                .catch(reason => {
                    this.handleError(reason);
                    this.update();
                })
        });
    }

    async componentDidMount() {
        window.changeBreadcrumbs(null);
        window.changeTitle('Rendelések')

        try {
            let result = await axios.get(process.env.REACT_APP_API_URL + "/galery/"+this.props.galeryId,this.axiosConfig())
            if (result.data.data !== undefined && result.data.data.name !== undefined) {
                this.setState({galery: result.data.data},() => {
                    window.changeTitle(this.state.galery.name + ' - Rendelések')
                });
            }
        } catch (e) {
            this.handleError(e);
        }

        this.update();
    }

    onDelete = (id) => {
        if (window.confirm('Biztosan törölni szerednéd?')) {
            this.delete(id);
        }
    }

    onChangePaymentStatus = async (orderId, payment_status) => {
        try {
            let result = await axios.put(process.env.REACT_APP_API_URL + "/order/" + orderId + "/status",{payment_status}, this.axiosConfig())
            this.notify(`A(z) ${orderId}. számú rendelés fizetési státusza: ${payment_status===1?'Nincs fizetve':'Fizetve'}`);
        } catch (e) {
            this.handleError(e)
        }
    }

    onSearch = (term) => {
        this.update(term)
    }

    render() {
        return (
            <OrdersView
                ordersBasePath={process.env.PUBLIC_URL+"/admin/orders"}
                orders={this.state.orders}
                loading={this.state.loading}
                onDelete={this.onDelete}
                onChangePaymentStatus={this.onChangePaymentStatus}
                onSearch={this.onSearch}
                script1Link={process.env.REACT_APP_API_URL+"/galery/"+this.props.galeryId+"/script1?token="+this.props.token}
                script2Link={process.env.REACT_APP_API_URL+"/galery/"+this.props.galeryId+"/script2?token="+this.props.token}
                script3Link={process.env.REACT_APP_API_URL+"/galery/"+this.props.galeryId+"/script3?token="+this.props.token}
            />
        );
    }
}

export default GaleriesPage;