import React from 'react';

const Error404View = () => {
    return (
        <>
            <nav className="navbar navbar-transparent navbar-absolute">
                <div className="container">
                    <div className="navbar-header">
                        <a href="/" className="navbar-brand">Illyés Bernadette Fotogaléria</a>
                    </div>
                </div>
            </nav>

            <div className="wrapper wrapper-full-page">
                <div className="full-page lock-page" data-color="green"
                     data-image="../../assets/img/background/background-5.png">
                    <div className="content">
                        <div className="not-found-msg">
                            <h1>404</h1>
                            <h3>Az oldal nem található</h3>
                        </div>
                    </div>
                    <footer className="footer footer-transparent">
                        <div className="container">
                            <div className="copyright">
                                &copy;
                                <script>document.write(new Date().getFullYear())</script>
                                , made with <i className="fa fa-heart heart"></i> by <a
                                href="https://www.creative-tim.com">Creative Tim</a>
                            </div>
                        </div>
                    </footer>
                    <div className="full-page-background not-found-background"></div>
                </div>
            </div>
        </>
    );
};

export default Error404View;