import React from 'react';

const Error404AdminView = () => {
    return (
        <>
            <div className="content">
                <div className="not-found-msg-admin">
                    <h1>404</h1>
                    <h3>Az oldal nem található</h3>
                </div>
            </div>
        </>
    );
};

export default Error404AdminView;