import React from 'react';

const OrdersView = ({ordersBasePath,loading,orders,onDelete,onChangePaymentStatus,onSearch,script1Link,script2Link,script3Link}) => {
    return (
        <div className="container-fluid main-container">
            <div className="card">
                <div className="card-header orders-header">
                    <div className="orders-header-row">
                        <h4 className="card-title orders-title">Rendelések</h4>
                        <div className="orders-search"><form className="form-inline" onSubmit={(ev) => {
                            ev.preventDefault();
                            onSearch(ev.target.elements.search.value);
                        }}><input className="form-control" placeholder="Keresés" name="search" /></form></div>
                    </div>

                </div>
                <div className="card-content">
                    <div className="bootstrap-table">
                        <div className="fixed-table-container">
                            <div className="fixed-table-body">
                                {loading ?
                                    <div className="spinner-border-container">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                        :
                                    <table className="table table-hover">
                                        <tbody>
                                        {orders.length===0?<tr><td>Nincs rendelés</td></tr>:orders.map((order) =>
                                            <tr key={order.id}>
                                                <td>#{order.id}</td>
                                                <td>{order.name}</td>
                                                <td><select className="form-control" onChange={(ev) => {onChangePaymentStatus(order.id,ev.target.value)}}>{[
                                                        {status: 1, title: "Nincs fizetve"},
                                                        {status: 2, title: "Fizetve"}
                                                    ].map((s) =>
                                                        <option value={s.status} selected={order.payment_status===s.status}>{s.title}</option>)}
                                                </select></td>
                                                <td className="td-actions text-right">
                                                    <div className="table-icons">
                                                        <a title="Megrendelőlap"
                                                                href={ordersBasePath+"/"+order.id}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="btn btn-simple btn-info btn-icon table-action view"
                                                                data-original-title="Link">
                                                            <i className="material-icons material-icons--outlined">request_page</i>
                                                        </a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <button rel="tooltip" title="Törlés"
                                                            onClick={() => onDelete(order.id)}
                                                            className="btn btn-simple btn-danger btn-icon table-action delete"
                                                            data-original-title="Törlés">
                                                        <i className="material-icons material-icons--outlined">delete_forever</i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="orders-header-row2">
                        <a className="btn btn-fill btn-primary btn-sm orders-header-download-button" href={script1Link}>Script1</a>
                        <a className="btn btn-fill btn-primary btn-sm orders-header-download-button" href={script2Link}>Script2</a>
                        <a className="btn btn-fill btn-primary btn-sm orders-header-download-button" href={script3Link}>Script3</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrdersView;