import React, {useEffect} from 'react';
import CartPartialView from "./CartPartialView";
import CopyRight from "./CopyRight";

const UserGaleryCartView = ({galery,cart,imagesBase,onCartPlus,onCartMinus,onCartRemove,onContinueToOrder,onBackToImages}) => {
    const forwardProps = {imagesBase,cart,onCartPlus,onCartMinus,onCartRemove};
    useEffect(() => {
        window.scroll(0,0)
    },[])
    return <div className="wrapper">
        <div className="main-panel">
            <div className="cover-under-dropdown"></div>
            <nav className="navbar navbar-default user-navbar">
                <div className="cover-under-dropdown-backdrop-lighter"></div>
                <div className="container-fluid">
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#charts">{galery && "Galéria: "+galery.name+" – Megrendelés"}</a>
                    </div>
                </div>
            </nav>

            <div className="content ">
                <div className="cover-under-dropdown-backdrop"></div>
                <div className="container-fluid main-container">
                    <div className="card order-page-cart">
                        <div className="card-header">
                            <h4 className="card-title">Kosár</h4>
                        </div>
                        <div className="card-header">
                            <button className="btn btn-default" onClick={onBackToImages}>Vissza a képekhez</button>
                            <button className="btn btn-primary pull-right" onClick={onContinueToOrder}>Megrendelés</button>
                            <div className="clearfix"></div>
                        </div>
                        <div className="card-content">

                            <CartPartialView allowChange={true} {...forwardProps} showZeroItems={true} />
                        </div>
                        <div className="card-footer">
                            <button className="btn btn-default" onClick={onBackToImages}>Vissza a képekhez</button>
                            <button className="btn btn-primary pull-right" onClick={onContinueToOrder}>Megrendelés</button>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer">
                <div className="container-fluid">
                    <CopyRight />
                </div>
            </footer>
        </div>
    </div>

};

export default UserGaleryCartView;