import React from 'react';
import {AddCircleOutlined, CardGiftcardOutlined, ClearOutlined, RemoveCircleOutlined} from "@material-ui/icons";

const CartPartialView = ({allowChange,imagesBase,cart,onCartPlus,onCartMinus,onCartRemove,showZeroItems=true}) => {
    return (
        <>
            {cart.digitalOnlyNotAllowed && <div className="alert alert-danger" role="alert">
                <span data-notify="message">Digitális fotó rendeléséhez még {cart.digitalOnlyNotAllowed} db digitális fotót válassz!</span>
                <a href="#" target="_blank" data-notify="url"></a>
            </div>}
            {cart.digitalVersionsMin && ( cart.digitalVersions ? <div className="alert alert-success alert-with-icon" role="alert">
                <span data-notify="icon"><CardGiftcardOutlined style={{fontSize: "33px", marginTop: "3px"}}  /></span>
                <span data-notify="message">A rendelésed elérte a {Intl?.NumberFormat ? (new Intl.NumberFormat('hu-HU').format(cart.digitalVersions)) : cart.digitalVersions} Ft-ot, ezért a rendelt fotók digitális változatát megkapod ajándékba.</span>
                <a href="#" target="_blank" data-notify="url"></a>
            </div> : <div className="alert alert-warning alert-with-icon" role="alert">
                <span data-notify="icon"><CardGiftcardOutlined style={{fontSize: "33px", marginTop: "3px"}}  /></span>
                <span data-notify="message">{Intl?.NumberFormat ? (new Intl.NumberFormat('hu-HU').format(cart.digitalVersionsMin)) : cart.digitalVersionsMin} Ft fölötti papírkép rendelés esetén a rendelt fotók digitális változatát megkapod ajándékba.</span>
                <a href="#" target="_blank" data-notify="url"></a>
            </div>)}
            <div className="bootstrap-table">
                <div className="fixed-table-container">
                    <div className="fixed-table-body">
                        <table className="table table-hover">
                            <colgroup>
                                <col className="table-img-col" span="1"/>
                                <col span="3"/>
                                <col className="table-count-col" span="1"/>
                                <col className="table-price-col" span="1"/>
                            </colgroup>
                            <tbody>
                            {cart.items.length ? <>
                                    {cart.items.map((item,index) => (
                                        !showZeroItems&&item.count===0?null:
                                        <tr key={index}>
                                            <td><img className="cart-image"
                                                     data-orientation="landscape"
                                                     src={item.image.smallimage}
                                                     alt={item.image.name}/></td>
                                            <td>{item.image.name}</td>
                                            <td>{item.size.size}</td>
                                            {allowChange===false?
                                                <td>
                                                    <div className="cart-item-count-nonchangeable">{item.count} db</div>
                                                </td>
                                            :
                                                <td>
                                                    <div className="cart-item-count"><span
                                                        className="cart-item-count-count">{item.count} db</span><span
                                                        className="cart-plus-minus-button-container">
                                                                            <button
                                                                                onClick={() => onCartPlus(item)}
                                                                                className="cart-plus-minus-button btn btn-simple btn-success btn-icon">
                                                                                <AddCircleOutlined style={{fontSize: "20px"}} />
                                                                            </button>
                                                                            <button
                                                                                onClick={() => onCartMinus(item)}
                                                                                className="cart-plus-minus-button btn btn-simple btn-danger btn-icon">
                                                                                <RemoveCircleOutlined style={{fontSize: "20px"}} />
                                                                            </button>
                                                                                </span>
                                                    </div>
                                                </td>
                                            }
                                            <td className="cart-item-price">{item.size.price * item.count} Ft</td>
                                            {allowChange === false ?
                                                null
                                                :
                                                <td className="cart-remove-cell">
                                                    <button
                                                        onClick={() => onCartRemove(item)}
                                                        className="cart-remove-button btn btn-simple btn-danger btn-icon">
                                                        <ClearOutlined style={{fontSize: "24px"}} />
                                                    </button>
                                                </td>
                                            }
                                        </tr>))}
                                    <tr key="total1">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td  className="cart-total-count-desc">Összes darab</td>
                                        <td className="cart-total-count"><b style={{whiteSpace: "nowrap"}}>{cart.totalCount} db</b></td>
                                    </tr>
                                    <tr key="total2">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="cart-total-price-desc">Fizetendő</td>
                                        <td className="cart-total-price"><b style={{whiteSpace: "nowrap"}}>{cart.totalAmount} Ft</b></td>
                                    </tr>
                                </> :
                                (<tr><td>A kosár üres</td></tr>)}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CartPartialView;