import React from 'react';
import * as PropTypes from "prop-types";
import Page from "../page/Page";
import AdminView from "../view/AdminView";

class AdminLayout extends Page {

    onLogout = (ev) => {
        ev.preventDefault();
        this.notify('Sikeres kijelentkezés')
        this.props.logOut();
    }

    render() {
        return <AdminView {...this.props} onLogout={this.onLogout} />
    }
}

AdminLayout.propTypes = {
    children: PropTypes.any,
    title: PropTypes.any,
    breadcrumbs: PropTypes.any,
    logOut: PropTypes.any
}

export default AdminLayout;