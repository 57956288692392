import React from 'react';
import GaleriesView from "../view/GaleriesView";
import axios from "axios";
import Page from "./Page";

class GaleriesPage extends Page {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errors: {},
            csoportok: []
        }
    }

    update() {
        this.setState({ loading:true },() => {
            axios.get(process.env.REACT_APP_API_URL+'/galery',this.axiosConfig())
                .then(result=>{
                    return result.data
                })
                .then(data=>{
                    return data.data
                })
                .then(data=>{
                    this.setState({loading:false, csoportok: data})
                })
                .catch(reason => {
                    //console.error("failed: ",reason);
                    this.handleError(reason);
                    this.setState({loading:false})
                })
        });
    }

    create(name,onSuccess) {
        // TODO: spinner on button
        axios.post(process.env.REACT_APP_API_URL+'/galery',{name},this.axiosConfig())
            .then(result => {
                this.notify('Galéria létrehozása sikerült')
                onSuccess();
                this.update();
            })
            .catch((reason) => {
                this.handleError(reason);
                this.update();
            })
    }

    delete(id,name) {
        this.setState({ loading:true },() => {
            axios.delete(process.env.REACT_APP_API_URL + '/galery/' + id,this.axiosConfig())
                .then(result => {
                    this.notify('A galéria "'+name+'" törlése sikerült')
                    this.update();
                })
                .catch(reason => {
                    this.handleError(reason);
                    this.update();
                })
        });
    }

    toggleVisible(id,onSuccess) {
        this.setState({loading: true}, () => {
            let csoportok = this.state.csoportok;
            const item = {...csoportok.find((item) => item.id===id)};
            /*item.visible = !item.visible;*/
            axios.put(process.env.REACT_APP_API_URL + '/galery/' + id,{'visible': !item.visible},this.axiosConfig())
                .then(result => {
                    if (result.data?.data?.visible !== undefined) {
                        this.notify('A galéria "'+result.data?.data?.name+'" '+(result.data.data.visible?'látható':'nem látható'));
                    }
                    this.update();
                })
                .catch(reason => {
                    this.handleError(reason);
                    this.update();
                })
        })
    }

    componentDidMount() {

        window.changeBreadcrumbs(null);
        window.changeTitle('Galériák')
        this.update();
    }

    onCreate = (value,onSuccess) => {
        if (value.length === 0) {
            this.setState({
                errors: {...this.state.errors,csoportNev: { error: true, message: "A csoport neve nem lehet üres."}}
            })
        } else {
            // TODO: created message
            this.create(value,onSuccess);
        }
    }

    onToggleVisible = (id) => {
        this.toggleVisible(id);
    }

    onDelete = (id,name) => {
        if (window.confirm('Biztosan törölni szerednéd?')) {
            this.delete(id, name);
        }
    }

    render() {
        return (
            <GaleriesView
                path={this.props.match.path}
                csoportok={this.state.csoportok}
                loading={this.state.loading}
                onCreate={this.onCreate}
                onDelete={this.onDelete}
                onToggleVisible={this.onToggleVisible}
                errors={this.state.errors}
            />
        );
    }
}

export default GaleriesPage;