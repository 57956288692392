import React from 'react';
import {Editor} from "@tinymce/tinymce-react";

/*Editor.prototype.getScriptSrc = function() {
    return process.env.PUBLIC_URL+"/assets/js/tinymce/tinymce.min.js"
}*/

const GaleryView = ({images,onFilesChange,onUpload,numFiles,imagesBase,accessToken,onDelete,onDeleteAll,sizes,
                        galery,greeting,newGreeting,onChangeGreeting,onSubmitModifyDetails,onNewSize,onDeleteSize,onModifySize,modifySizeId,onSaveSize,onCancelModifySize}) => {

    return(
        <div className="container-fluid main-container">
            <div className="card galery-upload">
                <div className="card-content">
                    <div className="galery-upload-row">
                        <form className="galery-upload-form">
                            <div className="btn-group">
                                <button type="button" className="btn btn-info" onClick={() =>
                                    onUpload(
                                        (progress) => window.upload_progress(progress),
                                        () => {
                                            window.upload_progress(false);
                                        }
                                    )
                                }><span className="btn-label"><i
                                    className="material-icons material-icons--outlined">publish</i> </span>Feltöltés
                                </button>
                                <label className="galery-file-upload btn btn-info">
                                    <input type="file" id="files" accept="image/png, image/jpeg" name="files" onChange={(ev)=>onFilesChange(ev.target.files)} multiple />
                                        <span className="btn-label"><i
                                            className="material-icons material-icons--outlined">folder_open</i></span><span>Fájlok
                                        kiválasztása</span>
                                </label>
                            </div>
                            <span className="galery-upload-selected-files">{numFiles} file kiválasztva</span>
                        </form>
                        <button onClick={onDeleteAll} className="btn btn-danger"><span className="btn-label"><i
                            className="material-icons material-icons--outlined">clear</i> </span> Összes törlése
                        </button>
                    </div>
                    <div className="galery-upload-progress">
                        <div className="galery-upload-progress-bar"></div>
                    </div>
                </div>
            </div>
            <div className="card card-plain galery-image-list-card">

                <div className="card-content">
                    <ul className="galery-image-list">

                        {images.map((image,index) =>
                                <li key={index} className="galery-item">
                                    <div className="galery-item-contents card">
                                        <a href={image.fullimage} target="_blank" rel="noopener noreferrer" className="galery-image-container">
                                            <img className="galery-image" src={image.smallimage} alt={image.smallimage} />
                                        </a>
                                        <div className="galery-item-footer">
                                            <span>{image.name}</span>
                                            <button onClick={() => onDelete(image.id,image.name)} className="btn btn-simple btn-danger btn-icon">
                                                <i className="material-icons material-icons--outlined">delete_forever</i>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                        )}

                    </ul>
                </div>
            </div>
            <div className="card">
                <form onSubmit={onSubmitModifyDetails}>
                    <div className="card-content card-content-textedit">
                        <div className="form-group">
                            <label className="control-label">Böngésző fejléc</label>
                            <input type="text" defaultValue={galery.pagetitle} className="form-control" name="pagetitle"/>
                        </div>
                        <div className="form-group">
                            <label className="control-label">Cím1</label>
                            <input type="text" defaultValue={galery.title} className="form-control" name="title"/>
                        </div>
                        <div className="form-group">
                            <label className="control-label">Cím2</label>
                            <input type="text" defaultValue={galery.subtitle} className="form-control" name="subtitle"/>
                        </div>
                        <label htmlFor="edit-text">Üdvözlőszöveg</label>
                        <Editor
                            initialValue={greeting}
                            tinymceScriptSrc={process.env.PUBLIC_URL+"/assets/js/tinymce/tinymce.min.js"}
                            init={{
                                menubar: false,
                                plugins: 'paste',
                                paste_auto_cleanup_on_paste : true,
                                paste_remove_styles: true,
                                paste_remove_styles_if_webkit: true,
                                paste_strip_class_attributes: true
                            }}
                            onEditorChange={onChangeGreeting}
                        />
                        <div style={{marginTop: "20px"}} className="form-group">
                            <label className="control-label">Minimum összeghatár ajándék digitális fotókhoz</label>
                            <input type="number" defaultValue={galery.digital_min_amount} className="form-control" name="digital_min_amount"/>
                        </div>
                        <div className="form-group">
                            <label className="control-label">Csak digitális rendelés minimum darabszám</label>
                            <input type="number" defaultValue={galery.digital_only_mincount} className="form-control" name="digital_only_mincount"/>
                        </div>
                    </div>
                    <div className="card-footer card-footer-textedit">
                            <button className="btn btn-primary" type="submit">Módosít</button>
                            {
                                newGreeting===null?
                                    <i className="textedit-done-icon text-success material-icons material-icons--outlined">done</i>
                                    : null
                            }
                    </div>
                </form>
            </div>
            <div className="card">
                <div className="card-content">
                    <div className="bootstrap-table table-meretek">
                        <div className="fixed-table-toolbar">
                            <form className="form-inline meretek">
                                <input className="form-control meretek-meret" name="size" type="text" placeholder="Méret"/>
                                <div className="input-group  meretek-ar">
                                    <input className="form-control" name="price" type="number" placeholder="Ár"/>
                                    <span className="input-group-addon">Ft</span>
                                </div>
                                <button type="button" className="btn btn-info" onClick={(ev) => {
                                    let form = ev.currentTarget.closest("form");
                                    let size = form.querySelector("input[name=size]").value;
                                    let price = form.querySelector("input[name=price]").value;
                                    onNewSize({size,price});
                                }}><span className="btn-label"><i
                                    className="material-icons material-icons--outlined">add</i></span> Hozzáad
                                </button>
                            </form>
                        </div>
                        <div className="fixed-table-container">
                            <div className="fixed-table-body">
                                <table className="table table-hover">
                                    <tbody>
                                    {sizes.map((size,index) => {
                                        if (size.id === modifySizeId) {
                                            return <tr key={index}>
                                                <td><input name="size" defaultValue={size.size} /></td>
                                                <td><input name="price" type="number" defaultValue={size.price} /> Ft</td>
                                                <td className="td-actions text-right">
                                                    <div className="table-icons">
                                                        <button rel="tooltip" title="Mégse"
                                                                className="btn btn-simple btn-danger btn-icon table-action view"
                                                                onClick={() => onCancelModifySize()}
                                                        >
                                                            <i className="material-icons material-icons--outlined">undo</i>
                                                        </button>
                                                        <button rel="tooltip" title="Módosítás"
                                                                className="btn btn-simple btn-warning btn-icon table-action view"
                                                                onClick={(ev) => onSaveSize(size.id,
                                                                    {
                                                                        size: ev.currentTarget.closest("tr").querySelector("input[name=size]").value,
                                                                        price: ev.currentTarget.closest("tr").querySelector("input[name=price]").value
                                                                    }
                                                                )}
                                                        >
                                                            <i className="material-icons material-icons--outlined">save</i>
                                                        </button>
                                                        <button rel="tooltip" title="Törlés"
                                                                className="btn btn-simple btn-danger btn-icon table-action view"
                                                                onClick={() => onDeleteSize(size.id)}
                                                        >
                                                            <i className="material-icons material-icons--outlined">close</i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        } else {
                                            return <tr key={index}>
                                                <td><span>{size.size}</span></td>
                                                <td><span>{size.price} Ft</span></td>
                                                <td className="td-actions text-right">
                                                    <div className="table-icons">
                                                        <button rel="tooltip" title="Módosítás"
                                                                className="btn btn-simple btn-warning btn-icon table-action view"
                                                                onClick={() => onModifySize(size.id)}
                                                        >
                                                            <i className="material-icons material-icons--outlined">edit</i>
                                                        </button>
                                                        <button rel="tooltip" title="Törlés"
                                                                className="btn btn-simple btn-danger btn-icon table-action view"
                                                                onClick={() => onDeleteSize(size.id)}
                                                        >
                                                            <i className="material-icons material-icons--outlined">close</i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </div>
    );
}

export default GaleryView;