import {Component} from 'react';

class Page extends Component {

    axiosConfig = () => {
        let headers = {};
        if (this.props.token) {
            headers['Authentication'] = 'Bearer '+this.props.token;
        }
        return {
            headers
        };
    }

    error(message) {
        window.showNotification('top','right','danger',message)
    }

    warning(message) {
        window.showNotification('top','right','warning',message)
    }

    notify(message) {
        window.showNotification('top','right','success',message)
    }

    handleError(error, msg = "Hiba történt") {

        if (error.response) {
            if (error.response.status === 404) {
                this.warning('A keresett cím nem található')
                return;
            }
            if (error.response.status === 401) {
                this.warning('Bejelentkezés szükséges');
                this.props.logOut();
                return
            }
            if (error.response.status >= 500) {
                this.error('Szerver hiba');
                return
            }
            if (error.response.status >= 400 && error.response.status <= 499) {
                console.error("error: ",error.response);
                this.error('Felhasználói hiba ('+error.response.status+'): '+error.response.data.message);
                return
            }
        } else {

        }
    }

}

export default Page;