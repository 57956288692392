import React from 'react';
import axios from "axios";
import UserGaleryView from "../view/UserGaleryView";
import Error404View from "../view/Error404View";
import UserGaleryOrderView from "../view/UserGaleryOrderView";
import Page from "./Page";
import UserGaleryCartView from "../view/UserGaleryCartView";

const cartVersion = 2

class UserGaleryPage extends Page {

    constructor(props) {
        super(props)
        this.state = {
            loadFailed: false,
            galery: null,
            images: [],
            loading: false,
            greeting: '',
            cartOpen: false,
            cart: {
                imageIds: {},
                items: [],
                totalCount: 0,
                totalAmount: 0,
                count: 0
            },
            sizes: [],
            zipcodes: null,
            cities: [],
            state: null,
            width: window.innerWidth,
        }
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    notify(message, noautodismiss = false) {
        if (noautodismiss){
            window.showNotificationNoTimer('top','center','success',message)
        } else {
            window.showNotification('top', 'center', 'success', message)
        }
    }

    error(message) {
        window.showNotificationNoTimer('top','center','danger',message)
    }

    warning(message) {
        window.showNotificationNoTimer('top','center','warning',message)
    }

    updateSizes = () => {
        this.setState({sizesLoading: true}, async() => {
            try {
                let result = await axios.get(process.env.REACT_APP_API_URL + '/galery/' + this.state.galery.id + '/sizes')
                this.setState({sizesLoading: false, sizes: result.data.data })
            } catch (e){
                this.handleError(e);
                this.setState({sizesLoading: false});
            }
        })
    }

    async componentDidMount() {
        window.changeTitle('Bettyfoto galéria');
        try {
            let result = await axios.get(process.env.REACT_APP_API_URL + '/usergalery/' + this.props.match.params.galeryLink)
            if (result.data.data !== undefined && result.data.data.name !== undefined) {
                this.setState({galery: result.data.data},() => {
                    this.update()
                    this.updateSizes()
                    this.loadCart()
                    this.downloadZipcodes()
                });
                if (result.data.data.pagetitle) window.changeTitle(result.data.data.pagetitle)
            }
            if (this.props.page && this.props.page === "thankyou") {
                let params = (new URL(document.location)).searchParams;
                let r = params.get("r")
                let s = params.get("s")
                try {
                    this.warning("A tranzakció ellenőrzése folyamatban van");
                    result = await axios.get(process.env.REACT_APP_API_URL + '/back?r=' + encodeURIComponent(r) + "&s=" + encodeURIComponent(s));
                    let status = result.data?.transaction_status;
                    let id = result.data?.transaction_id;
                    switch (status) {
                        case 'SUCCESS':
                            this.notify("Sikeres tranzakció. <br />SimplePay tranzakció azonosító: "+id,true);
                            break;
                        case 'FAIL':
                            this.error("Sikertelen tranzakció.<br />SimplePay tranzakció azonosító: "+id +
                                "<br />" +
                                "Kérjük, ellenőrizze a tranzakció során megadott adatok helyességét.<br />" +
                                "Amennyiben minden adatot helyesen adott meg, a visszautasítás okának<br />" +
                                "kivizsgálása érdekében kérjük, szíveskedjen kapcsolatba lépni" +
                                "kártyakibocsátó bankjával.",true);
                            break;
                        case 'TIMEOUT':
                            this.error("Ön túllépte a tranzakció elindításának lehetséges maximális idejét",true);
                            break;
                        case 'CANCEL':
                            this.warning('Ön megszakította a fizetést',true);
                            break;
                        default:
                            throw new Error();
                    }
                    this.onClearCart();
                    //this.props.history.replace("/galery/"+this.props.match.params.galeryLink);
                } catch (e) {
                    this.error("Hiba történt a tranzakció státuszának ellenőrzése közben.");
                }
            }
        } catch (e) {
            if (e.response?.status === 404) {
                this.setState({loadFailed: true})
            } else {
                this.handleError(e);
            }
        }

    }

    update = () => {
        this.setState({ loading:true },async () => {
            try {
                let result = await axios.get(process.env.REACT_APP_API_URL + '/usergalery/' + this.props.match.params.galeryLink + '/images')
                this.setState({loading: false, images: result.data.data, accessToken: result.data.access_token})
            } catch (e) {
                this.handleError(e);
                this.setState({loading:false})
            }
        });
    }

    saveCart = () => {
        try {
            window.localStorage.setItem('ib_'+this.props.match.params.galeryLink+'_cart',JSON.stringify(this.state.cart))
        } catch (e) {
            this.handleError(e);
        }

    }

    loadCart = () => {
        try {
            let cartSerialized = window.localStorage.getItem('ib_'+this.props.match.params.galeryLink+'_cart',this.state.cart);
            let cart = JSON.parse(cartSerialized);
            if (cart && typeof cart.items === "object" && typeof cart.imageIds === "object") {
                console.log("loading cart v"+cart.cartVersion)
                if (!cart.cartVersion || cart.cartVersion < cartVersion) {
                    this.addCartTotals(cart)
                }
                this.setState({cart})
            }
        } catch (e) {
            this.handleError(e);
        }

    }

    onShowImage = (image) => {
        this.props.history.push("/galery/"+this.props.match.params.galeryLink+"/"+image.id)
    }

    onCloseImage = () => {
        this.props.history.goBack();
        //this.props.history.push(process.env.PUBLIC_URL+"/galery/"+this.props.match.params.galeryLink)
    }

    onOpenCart = () => {
        this.setState({cartOpen:true});
    }

    onCloseCart = () => {
        this.setState({cartOpen:false});
    }

    onToggleCart = () => {
        this.setState({cartOpen:!this.state.cartOpen});
    }

    addCartTotals = (cart) => {
        const imgset = new Set();

        cart.items.filter(it => it.size.size.toLowerCase().includes("digitális")).forEach(it => {if (it.count > 1) {it.count = 1} })

        for (let item of cart.items) {
            imgset.add(item.image.name);
        }
        cart.count = imgset.size;
        cart.totalCount = cart.items.reduce((p,c) => p+c.count,0)
        cart.totalAmount = cart.items.reduce((p,c) => p+c.size.price*c.count,0);
        cart.cartVersion = cartVersion

        const digitalCount = cart.items.filter(it => it.size.size.toLowerCase().includes("digitális")).reduce((p,c) => p+c.count,0)
        cart.digitalVersionsMin = this.state.galery.digital_min_amount;
        cart.digitalVersions = this.state.galery.digital_min_amount && cart.totalAmount >= this.state.galery.digital_min_amount && this.state.galery.digital_min_amount
        cart.containsDigital = this.state.galery.digital_only_mincount && digitalCount > 0
        cart.digitalOnlyNotAllowed = (!cart.containsDigital || cart.digitalVersions) ? undefined : (digitalCount < this.state.galery.digital_only_mincount ? this.state.galery.digital_only_mincount - digitalCount : false)
    }

    onAddToCart = (image) => {

        if (this.state.sizes.length === 0) {
            this.warning('Nincs rendelhető méret')
            return;
        }
        this.setState((state) => {
            const newState = {cart:{items: [...state.cart.items], imageIds: {...state.cart.imageIds}}}
            //let modified = false;
            for (let size of state.sizes) {
                let existing = newState.cart.items.findIndex((item) => item.image.id === image.id && item.size.id === size.id);
                if (existing === -1) {
                    newState.cart.items.push({image,size,count:0})
                    //modified = true;
                }/* else {
                    newState.cart.items[existing] = {...newState.cart.items[existing], count:newState.cart.items[existing].count+1}
                }*/
            }
            newState.cart.imageIds[image.id] = 1;
            this.addCartTotals(newState.cart);
            return newState;
        },() => {

            this.saveCart();
        })
    }

    onClearCart = () => {
        this.setState((state)=>{
            return {cart:{items: [], imageIds: {}}};
        },() => {
            this.saveCart();
        });
    }

    onCartPlus = (item) => {
        this.setState((state) => {
            const newState = {cart:{items: [...state.cart.items], imageIds:{...state.cart.imageIds}}}
            let existing = newState.cart.items.findIndex((it) => it.image.id === item.image.id && it.size.id === item.size.id);
            if (existing !== -1) {
                newState.cart.items[existing] = {...newState.cart.items[existing], count: newState.cart.items[existing].count+1};
            }
            this.addCartTotals(newState.cart);
            return newState;
        },() => {
            this.saveCart();
        });
    }

    onCartMinus = (item) => {
        this.setState((state) => {
            const newState = {cart:{items: [...state.cart.items], imageIds:{...state.cart.imageIds}}}
            let existing = newState.cart.items.findIndex((it) => item.image.id === it.image.id && item.size.id === it.size.id);
            if (existing !== -1 && newState.cart.items[existing].count > 0) {
                newState.cart.items[existing] = {...newState.cart.items[existing], count: newState.cart.items[existing].count-1};
            }
            this.addCartTotals(newState.cart);
            return newState;
        },() => {
            this.saveCart();
        });
    }

    onCartRemove = (item) => {
        this.setState((state) => {
            const items = state.cart.items.filter((it) => it.image.id !== item.image.id || it.size.id !== item.size.id);
            const imageIds = {...state.cart.imageIds};
            if (items.filter((it) => it.image.id === item.image.id).length === 0) {
                delete imageIds[item.image.id]
            }
            let newState = {cart:{items,imageIds}};
            this.addCartTotals(newState.cart);
            return newState;
        },() => {
            this.saveCart();
        });
    }

    downloadZipcodes = async () => {
        if (this.state.zipcodes === null) {
            try {
                const zipcodes = await import('../zipcodes_hu.json');
                const byzip = {}
                for (let zipcode of zipcodes.zipcodes) {
                    if (byzip[zipcode.zip] === undefined){
                        byzip[zipcode.zip] = {cities:{},states: {}}
                    }
                    byzip[zipcode.zip].cities[zipcode.city] = 1;
                    byzip[zipcode.zip].state = zipcode.state;
                }
                this.setState({zipcodes:byzip})
            } catch (e) {
                console.error(e)
            }
            /**/
        }
    }

    onChaneZip = (ev) => {
        let el = ev.currentTarget;
        let zipcode;
        if (el.validity.valid && this.state.zipcodes !== null && (zipcode = this.state.zipcodes[el.value]) !== undefined) {
            this.setState({cities: Object.keys(zipcode.cities), state: zipcode.state})
        } else {
            this.setState({cities: [], state: null})
        }
    }

    onContinueToCart = async () => {
        this.props.history.push("/galery/" + this.props.match.params.galeryLink + "/cart");
    }

    onContinueToOrder = async () => {
        if (this.state.cart?.totalCount) {
            this.setState({cartOpen: false}, () => {
                this.props.history.push("/galery/" + this.props.match.params.galeryLink + "/order");
            })
        } else {
            if (this.state.cart.items.length) {
                this.warning('A kosárban mindegyik tétel 0 db')
            } else {
                this.warning('A kosár üres')
            }
        }

    }

    onBackToImages = () => {
        //TODO: back if the last page was the same
        this.props.history.push("/galery/"+this.props.match.params.galeryLink)
    }

    onSubmitOrder = async (ev) => {
        ev.preventDefault();
        if (this.state.cart.digitalOnlyNotAllowed) {
            this.error(`Digitális fotó rendeléséhez még ${this.state.cart.digitalOnlyNotAllowed} db digitális fotót válassz!`)
            return;
        }
        const els = ev.target.elements;
        const fields = ['name','email','phone','zip','city','state','address','comment'];
        const request = {};
        if (this.state.cart.totalAmount) {
            fields.push('payment');
        }
        request.payment = -1;
        for (let field of fields) {
            request[field] = els[field].value;
        }
        request.galery_id = this.state.galery.id;
        request.cart_items = this.state.cart.items.filter((item) => item.count > 0);

        try {
            let result = await axios.post(process.env.REACT_APP_API_URL + '/order',request);
            const id = result.data.data?.id;
            if (!id){
                this.error('Hiba történt a rendelés feldolgozása közben');
                return;
            }
            if (this.state.cart.totalAmount) {
                const returnUrl = process.env.REACT_APP_REDIRECT_BASE_URL + '/galery/' + this.props.match.params.galeryLink + '/thankyou';
                result = await axios.post(process.env.REACT_APP_API_URL + '/order/' + id + '/pay', {return_url: returnUrl})
                const paymentUrl = result.data.payment_url;
                if (!paymentUrl) {
                    this.error('Hiba történt a rendelés feldolgozása közben');
                    return;
                }
                this.notify('Most átirányítunk a biztonságos fizetőoldalra.', true);
                window.setTimeout(async () => {
                    window.location.replace(paymentUrl);
                }, 3000);
            } else {
                this.notify("A megrendelés sikeresen elküldve");
                this.onClearCart();
                this.props.history.push("/galery/"+this.props.match.params.galeryLink+"/thankyou-nopayment");
            }
        } catch (e) {
            this.handleError(e);
        }
    }

    render() {
        if (this.state.loadFailed) {
            return <Error404View />
        }
        if (this.state.galery == null) {
            return null;
        }

        let bigimage = null;
        let handlers = {}
        for (let n of Object.getOwnPropertyNames(this)) {
            if (typeof this[n] === "function" && n.startsWith('on')) {
                handlers[n] = this[n]
            }
        }
        if (this.props.match.params.image && this.state.images) {
            let filtered = this.state.images.filter((image) => {return image.id.toString()===this.props.match.params.image});
            if (filtered && filtered.length > 0) {
                bigimage = filtered[0].fullimage;
            }
        }
        if (this.props.page && this.props.page==="order") {
            return <UserGaleryOrderView {...this.props} {...this.state} imagesBase={process.env.REACT_APP_API_URL+"/"} {...handlers} />
        } else if (this.props.page && this.props.page==="cart") {
            return <UserGaleryCartView {...this.props} {...this.state} imagesBase={process.env.REACT_APP_API_URL+"/"} {...handlers} />
        } else {
            return <UserGaleryView {...this.props} {...this.state} imagesBase={process.env.REACT_APP_API_URL+"/"} bigimage={bigimage} {...handlers}/>
        }
    }
}

export default UserGaleryPage;