import React, {Component} from 'react';

class CopyRight extends Component {
    render() {
        return (
            <div className="copyright pull-right">
                &copy;2020, made by <a
                href="https://dynamicprogrammingsolutions.com">Dynamic Programming Solutions</a>
            </div>
        );
    }
}

export default CopyRight;