import React, {Component} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import GaleriesPage from "./page/GaleriesPage";
import AdminLayout from "./page/AdminLayout";
import GaleryPage from "./page/GaleryPage";
import UserGaleryPage from "./page/UserGaleryPage";
import Login from "./Login";
import Error404View from "./view/Error404View";
import OrdersPage from "./page/OrdersPage";
import OrderPage from "./page/OrderPage";

class App extends Component {

    constructor(props){
        super(props);
        this.state = {
            title: "Illyés Bernadette Fotogaléria",
            breadcrumbs: null
        }
        window.changeTitle = (title) => this.changeTitle(title);
        window.changeBreadcrumbs = this.changeBreadcrumbs;
    }

    changeTitle = (title,windowTitle) => {
        if (windowTitle === undefined) windowTitle = title;
        window.document.title = windowTitle;
        this.setState({title});
    }

    changeBreadcrumbs = (breadcrumbs) => {
        if (breadcrumbs !== this.state.breadcrumbs) {
            this.setState({breadcrumbs});
        }
    }

    render() {
        return (

            <div className="App">
                <Router basename={process.env.PUBLIC_URL}>
                    <Switch>
                        <Route path="/admin" render={({...adminProps}) => {
                            return <Login {...adminProps} render={({match, ...loginProps}) =>
                                <Switch>
                                    <Route path={match.path+"/galeries"} exact={true} render={(props) =>
                                        <AdminLayout {...loginProps} title={this.state.title} breadcrumbs={this.state.breadcrumbs}>
                                            <GaleriesPage {...loginProps} {...props} />
                                        </AdminLayout>
                                    }/>
                                    <Route path={match.path+"/galeries/:galeryId/orders"} render={(props) =>
                                        <AdminLayout {...loginProps} title={this.state.title} breadcrumbs={this.state.breadcrumbs}>
                                            <OrdersPage {...props.match.params} {...loginProps} {...props} />
                                        </AdminLayout>
                                    } />
                                    <Route path={match.path+"/galeries/:galeryId"} render={(props) =>
                                        <AdminLayout {...loginProps} title={this.state.title} breadcrumbs={this.state.breadcrumbs}>
                                            <GaleryPage {...loginProps} {...props} />
                                        </AdminLayout>
                                    } />
                                    <Route path={match.path+"/orders/:orderId"} render={(props) =>
                                        <OrderPage {...props.match.params} {...loginProps} {...props} />
                                    } />
                                    <Redirect to={match.path+"/galeries"} />
                                </Switch>
                            } />
                        }} />
                        <Route path="/galery/:galeryLink/cart" render={(props) =>
                            <UserGaleryPage {...props} page="cart" />
                        } />
                        <Route path="/galery/:galeryLink/order" render={(props) =>
                            <UserGaleryPage {...props} page="order" />
                        } />
                        <Route path="/galery/:galeryLink/thankyou" render={(props) =>
                            <UserGaleryPage {...props} page="thankyou" />
                        } />
                        <Route path="/galery/:galeryLink/:image?" component={UserGaleryPage} />
                        <Route path="/" component={Error404View} />
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;
