import {Link} from "react-router-dom";
import React from "react";
import CopyRight from "./CopyRight";

const AdminView = ({children, title, breadcrumbs, onLogout}) => {
    return (
        <div className="wrapper">

            <div className="main-panel">
                <nav className="navbar navbar-default">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar bar1"></span>
                                <span className="icon-bar bar2"></span>
                                <span className="icon-bar bar3"></span>
                            </button>
                            <a className="navbar-brand" href={process.env.PUBLIC_URL + "/admin"}>{title}</a>
                        </div>
                        <div className="collapse navbar-collapse">
                            <ul className="nav navbar-nav navbar-right">
                                <li>
                                    <a href={process.env.PUBLIC_URL + "/admin/login"} onClick={onLogout} className="btn-magnify">
                                        <i className="material-icons material-icons--outlined">exit_to_app</i>
                                        <p className="hidden-md hidden-lg">
                                            Kijelentkezés
                                        </p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                {breadcrumbs ?
                    <div className="breadcrumbs-container">
                        <ol className="breadcrumb">
                            {breadcrumbs.up.map((page,i) => <li key={i}><Link to={page.link}>{page.title}</Link></li>)}
                            <li className="active">{breadcrumbs.active.title}</li>
                        </ol>
                    </div>
                    : null}

                <div className="content">
                    {children}
                </div>

                <footer className="footer">
                    <div className="container-fluid">
                        <CopyRight />
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default AdminView;