import React from 'react';
import axios from "axios";
import Page from "./Page";
import OrderView from "../view/OrderView";

class OrderPage extends Page {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            order: null,
            cart: null,
            galery: null
        }
    }

    update() {
        this.setState({ loading:true },() => {
            axios.get(process.env.REACT_APP_API_URL+'/order/'+this.props.orderId,this.axiosConfig())
                .then(result=>{
                    return result.data
                })
                .then(data=>{
                    return data.data
                })
                .then(data=>{
                    this.setState({order: data, cart: this.getCart(data)}, async () => {
                        try {
                            let result = await axios.get(process.env.REACT_APP_API_URL + "/galery/"+this.state.order.galery_id,this.axiosConfig())
                            if (result.data.data !== undefined && result.data.data.name !== undefined) {
                                this.setState({galery: result.data.data, loading:false},() => {
                                    window.changeTitle(this.state.galery.name + ' - Rendelés #'+this.props.orderId)
                                });
                            }
                        } catch (e) {
                            this.handleError(e);
                        }
                    })
                })
                .catch(reason => {
                    this.handleError(reason);
                    this.setState({loading:false})
                })
        });
    }

    getCart(data) {
        return this.addCartTotals({
            items: data.cart_items.sort((a,b) => {
                if (a.size.size < b.size.size) return -1;
                if (a.size.size > b.size.size) return 1;
                if (a.image.name < b.image.name) return -1;
                if (a.image.name > b.image.name) return 1;
                return 0;
            })
        })
    }

    addCartTotals = (cart) => {
        cart.totalCount = cart.items.reduce((p,c) => p+c.count,0)
        cart.totalAmount = cart.items.reduce((p,c) => p+c.size.price*c.count,0);
        return cart;
    }

    componentDidMount() {
        window.changeBreadcrumbs(null);
        window.changeTitle('Rendelés #'+this.props.orderId)
        this.update();
    }

    render() {
        return (
            <OrderView
                galery={this.state.galery}
                order={this.state.order}
                cart={this.state.cart}
                loading={this.state.loading}
            />
        );
    }
}

export default OrderPage;