import React, {useRef} from 'react';
import {Link} from "react-router-dom";
import copy from 'copy-to-clipboard';

const GaleriesView = ({path,loading,csoportok,onCreate,onToggleVisible,onDelete,errors}) => {
    const csoportNev = useRef(null);
    const submitHandle = () => {
        onCreate(csoportNev.current.value,() => {
            csoportNev.current.value = "";
        });
    }
    return (
        <div className="container-fluid main-container">
            <div className="card">
                <div className="card-content">
                    <div className="bootstrap-table">
                        <div className="fixed-table-toolbar">
                            <form onSubmit={(ev) => {ev.preventDefault(); submitHandle()}} className="form-inline csoport-hozzaad">
                                <input type="text" placeholder="Galéria Neve" ref={csoportNev} className={`form-control ${errors.csoportNev?'error':''}`}/>
                                <button type="button" onClick={submitHandle} className="btn btn-fill btn-info">Hozzáad</button>
                                {errors.csoportNev?<label className='error'>{errors.csoportNev.message}</label>:<></>}
                            </form>
                        </div>
                        <div className="fixed-table-container">
                            <div className="fixed-table-body">
                                {loading ?
                                    <div className="spinner-border-container">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                        :
                                    <table className="table table-hover">
                                        <tbody>
                                        {csoportok.length===0?<tr><td>Nincs csoport</td></tr>:csoportok.map((csoport) =>
                                            <tr key={csoport.id}>
                                                <td><Link to={path+"/"+csoport.id}>{csoport.name}</Link></td>
                                                <td className="td-actions text-right">
                                                    <div className="table-icons">


                                                        <button rel="tooltip" title="Láthatóság"
                                                                onClick={() => onToggleVisible(csoport.id)}
                                                                className="btn btn-simple btn-warning btn-icon table-action view"
                                                                data-original-title="Láthatóság">
                                                            <i className="material-icons material-icons--outlined">{csoport.visible?"visibility":"visibility_off"}</i>
                                                        </button>
                                                        <a title="Link"
                                                           href={process.env.PUBLIC_URL+"/galery/"+csoport.link}
                                                           target="_blank"
                                                           rel="noopener noreferrer"
                                                           className="btn btn-simple btn-info btn-icon table-action view"
                                                           data-original-title="Link">
                                                            <i className="material-icons material-icons--outlined">link</i>
                                                        </a>
                                                        <button title="Link másolása"
                                                                onClick={() => {
                                                                    let url = window.location.protocol+"//"+window.location.hostname+(window.location.port&&window.location.port!==80&&window.location.port!==443?":"+window.location.port:"")+process.env.PUBLIC_URL+"/galery/"+csoport.link;
                                                                    copy(url);
                                                                }}
                                                                className="btn btn-simple btn-info btn-icon table-action view"
                                                                data-original-title="Link">
                                                            <i className="material-icons material-icons--outlined">content_copy</i>
                                                        </button>
                                                        <Link title="Megrendelések"
                                                              to={path+"/"+csoport.id+"/orders"}
                                                              target="_blank"
                                                              rel="noopener noreferrer"
                                                              className="btn btn-simple btn-success btn-icon table-action view"
                                                              data-original-title="Link">
                                                            <i className="material-icons material-icons--outlined">shopping_cart</i>
                                                        </Link>
                                                        <button rel="tooltip" title="Törlés"
                                                                onClick={() => onDelete(csoport.id,csoport.name)}
                                                                className="btn btn-simple btn-danger btn-icon table-action delete"
                                                                data-original-title="Törlés">
                                                            <i className="material-icons material-icons--outlined">delete_forever</i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GaleriesView;