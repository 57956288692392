import React from 'react';

const OrderView = ({galery,loading,order,cart}) => {
    return <div className="container-fluid main-container order-page">
        {(() => {
            if (!loading) {
                if (!order || !cart) {
                    return <div>A rendelés adatainak betöltése nem sikerült</div>
                }
                return (
                    <>
                        <h2>Rendelés #{order.id}</h2>

                        <h3>Rendelés adatai</h3>
                        <div className="bootstrap-table">
                            <div className="fixed-table-container">
                                <div className="fixed-table-body">
                                    <table className="table">
                                        <tbody>
                                            {galery!==null?<tr><td>Galéria</td><td>{galery.name}</td></tr>:null}
                                            <tr><td>Név</td><td>{order.name}</td></tr>
                                            <tr><td>email</td><td>{order.email}</td></tr>
                                            <tr><td>Telefon</td><td>{order.phone}</td></tr>
                                            <tr><td>Irányítószám</td><td>{order.zip}</td></tr>
                                            <tr><td>Megye</td><td>{order.state}</td></tr>
                                            <tr><td>Város</td><td>{order.city}</td></tr>
                                            <tr><td>Cím</td><td>{order.address}</td></tr>
                                            <tr><td>Megjegyzés</td><td>{order.comment}</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <h3>Kosár</h3>
                        <div className="bootstrap-table">
                            <div className="fixed-table-container">
                                <div className="fixed-table-body">
                                    <table className="table">
                                        <colgroup>
                                            <col span="3"/>
                                            <col className="table-count-col" span="1"/>
                                            <col className="table-price-col" span="1"/>
                                        </colgroup>
                                        <tbody>
                                            {cart.items.map((item,index) => (
                                                <tr key={index}>
                                                    <td>{item.image.name}</td>
                                                    <td>{item.size.size}</td>
                                                    <td>
                                                        <div className="cart-item-count-nonchangeable">{item.count} db</div>
                                                    </td>
                                                    <td className="cart-item-price">{item.size.price * item.count} Ft</td>
                                                </tr>))}
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td  className="cart-total-count-desc">Összes darab</td>
                                                <td className="cart-total-count"><b style={{whiteSpace: "nowrap"}}>{cart.totalCount} db</b></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td className="cart-total-price-desc">Fizetendő</td>
                                                <td className="cart-total-price"><b style={{whiteSpace: "nowrap"}}>{cart.totalAmount} Ft</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                );
            } else {
                return <div>Betöltés folyamatban...</div>;
            }
        })()}
    </div>
};

export default OrderView;